import { useAuthStore } from "@/store";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import axios from "axios";
import {
  db_checkin,
  db_open_canteen,
  nativeCamCheckString,
  lunchbook,
} from "@/config_vars";
import dayjs from "dayjs";
import { alertController } from "@ionic/vue";
import { appGetUserCurrentSession, appWriteGetUser } from "../store/firebase";

const authCheck = (to: any, from: any, next: any) => {
  const store = useAuthStore();
  if (store.isLoggedIn) {
    if (to.name === "login") {
      //TO DO HERE
      switch (store.profile.role) {
        case "eating_customer":
          if (to.params.fromsignup === nativeCamCheckString) {
            next("/ecindex/" + nativeCamCheckString);
          } else {
            next({ name: "EatingCustomerHomepage" });
          }
          break;
        case "main_chef":
          next({ name: "MainChefHomepage" });
          break;
        case "hr":
          next({ name: "HrHomepage" });
          break;
        default:
          next({ name: "EatingCustomerHomepage" });
          break;
      }
    } else {
      if (to.name === "login") {
        next();
      } else {
        next({ name: "login" });
      }
    }
  } else {
    next();
  }
};

const authBookCheck = async (to: any, from: any, next: any) => {
  const store = useAuthStore();
  const today = dayjs().format("YYYY-MM-DD");
  const onAlreadyCheckedSound = new Audio("/assets/sounds/alreadyChecked.mp3");

  if (store.isLoggedIn) {
    switch (to.name) {
      case "EatingCustomerHomepage":
        switch (store.profile.role) {
          case "eating_customer":
            next();
            break;
          case "main_chef":
            next(from);
            break;
          case "hr":
            next(from);
            break;
          default:
            next();
            break;
        }
        break;
      case "Booking Page":
        switch (store.profile.role) {
          case "eating_customer":
            next();
            break;
          case "main_chef":
            next(from);
            break;
          case "hr":
            next(from);
            break;
          default:
            next();
            break;
        }
        break;
      case "Flexi Booking Page":
        switch (store.profile.role) {
          case "eating_customer":
            next();
            break;
          case "main_chef":
            next(from);
            break;
          case "hr":
            next(from);
            break;
          default:
            next();
            break;
        }
        break;
      case "Profile Page":
        switch (store.profile.role) {
          case "eating_customer":
            next();
            break;
          case "main_chef":
            next(from);
            break;
          case "hr":
            next(from);
            break;
          default:
            next();
            break;
        }
        break;
      case "QR Code CheckIn":
        switch (store.profile.role) {
          case "eating_customer": {
            const user = await appWriteGetUser();
            let dbcantOpenHour = 11;
            let dbcantOpenMinutes = 0;
            let dbcantCloseHour = 13;
            let dbcantCloseMinutes = 0;
            if (user) {
              const formData = new FormData();
              formData.append("getOpnClsHoursMins", "true");
              formData.append("uid", user.$id);
              const usess = await appGetUserCurrentSession();
              formData.append("usess", JSON.stringify(usess));
              await axios({
                method: "post",
                url: db_open_canteen,
                data: formData,
              }).then((response) => {
                dbcantOpenHour = response.data.openingHours;
                dbcantOpenMinutes = response.data.openingMinutes;
                dbcantCloseHour = response.data.closingHours;
                dbcantCloseMinutes = response.data.closingMinutes;
              });
              if (
                dayjs() <
                  dayjs().hour(dbcantOpenHour).minute(dbcantOpenMinutes) ||
                dayjs() >
                  dayjs().hour(dbcantCloseHour).minute(dbcantCloseMinutes)
              ) {
                if (from.name === "login") {
                  next({ name: "EatingCustomerHomepage" });
                  return;
                }
                onAlreadyCheckedSound.play();
                const notOpen = await alertController.create({
                  cssClass: "canteen-notopen-alert-class",
                  header: "Kantinen er ikke åben",
                  message:
                    "Du kan kun foretage check-in, når kantinen er åben. <div class='canteenClosedPopupTimes'>" +
                    dbcantOpenHour.toString() +
                    ":" +
                    (dbcantOpenMinutes < 10
                      ? "0" + dbcantOpenMinutes.toString()
                      : "" + dbcantOpenMinutes.toString()) +
                    " - " +
                    dbcantCloseHour.toString() +
                    ":" +
                    (dbcantCloseMinutes < 10
                      ? "0" + dbcantCloseMinutes.toString()
                      : "" + dbcantCloseMinutes.toString()) +
                    "</div>",
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: "Ok",
                      id: "confirm-button",
                      handler: () => {
                        return true;
                      },
                    },
                  ],
                });
                await notOpen.present();

                await notOpen.onDidDismiss();
                next(from);
                return;
              }
            }
            await axios
              .get(db_checkin, {
                params: {
                  user: store.user.userId,
                  tDate: today,
                  getData: true,
                  usess: await appGetUserCurrentSession(),
                },
              })
              .then(async (response) => {
                if (response.data.checkedin) {
                  if (response.data.booked) {
                    if (response.data.checkedin) {
                      if (
                        response.data.checkedin.bookStatus === "1" &&
                        response.data.booked.bookStatus === "1"
                      ) {
                        if (from.name === "login") {
                          next({ name: "EatingCustomerHomepage" });
                          return;
                        }
                        onAlreadyCheckedSound.play();
                        const alertAlreadyChecked =
                          await alertController.create({
                            cssClass: "already-checked-alert-class",
                            header: "Du er allerede checket in idag",
                            message: "Tak fordi du reducerer vores madspild",
                            backdropDismiss: false,
                            buttons: [
                              {
                                text: "O.K.",
                                id: "confirm-button",
                                handler: () => {
                                  return true;
                                },
                              },
                            ],
                          });
                        await alertAlreadyChecked.present();

                        await alertAlreadyChecked.onDidDismiss();
                        next(from);
                      }

                      if (
                        response.data.checkedin.bookStatus === "1" &&
                        (response.data.booked.bookStatus === "0" ||
                          !response.data.booked)
                      ) {
                        if (from.name === "login") {
                          next({ name: "EatingCustomerHomepage" });
                          return;
                        }
                        onAlreadyCheckedSound.play();
                        const alertAlreadyChecked =
                          await alertController.create({
                            cssClass: "already-checked-alert-class",
                            header: "Du er allerede checket in idag",
                            message:
                              "Men du havde ikke booket frokost og har nu øget vores ressourcespild",
                            backdropDismiss: false,
                            buttons: [
                              {
                                text: "O.K.",
                                id: "confirm-button",
                                handler: () => {
                                  return true;
                                },
                              },
                            ],
                          });
                        await alertAlreadyChecked.present();

                        await alertAlreadyChecked.onDidDismiss();
                        next(from);
                      }

                      if (
                        response.data.booked.bookStatus === "1" &&
                        response.data.checkedin.bookStatus === "0"
                      ) {
                        next();
                      }

                      if (
                        response.data.booked.bookStatus === "0" &&
                        response.data.checkedin.bookStatus === "0"
                      ) {
                        next();
                      }
                    }
                  } else {
                    if (response.data.checkedin.bookStatus === "1") {
                      if (from.name === "login") {
                        next({ name: "EatingCustomerHomepage" });
                        return;
                      }
                      onAlreadyCheckedSound.play();
                      const alertAlreadyChecked = await alertController.create({
                        cssClass: "already-checked-alert-class",
                        header: "Du er allerede checket in idag",
                        message:
                          "Men du havde ikke booket frokost og har nu øget vores ressourcespild",
                        backdropDismiss: false,
                        buttons: [
                          {
                            text: "O.K.",
                            id: "confirm-button",
                            handler: () => {
                              return true;
                            },
                          },
                        ],
                      });
                      await alertAlreadyChecked.present();

                      await alertAlreadyChecked.onDidDismiss();
                      next(from);
                    }

                    if (
                      response.data.checkedin.wasBooked == false &&
                      response.data.checkedin.wasChecked == false
                    ) {
                      await axios
                        .get(lunchbook, {
                          params: {
                            uid: store.user.userId,
                            check_paused: "true",
                            usess: await appGetUserCurrentSession(),
                          },
                        })
                        .then(async (response) => {
                          if (response.data == "4") {
                            const userPausedAlert =
                              await alertController.create({
                                cssClass: "user-paused-alert-class",
                                header: "Din profil er sat på pause",
                                message:
                                  "Kontakt venligst HR-afdelingen, hvis du ønsker at få din konto genaktiveret.",
                                backdropDismiss: false,
                                buttons: [
                                  {
                                    text: "O.K.",
                                    id: "confirm-button",
                                    handler: () => {
                                      return true;
                                    },
                                  },
                                ],
                              });
                            await userPausedAlert.present();

                            await userPausedAlert.onDidDismiss();
                            next(from);
                            return;
                          } else {
                            next();
                          }
                        });
                    }
                  }
                } else {
                  next();
                }
              });
            break;
          }
          case "main_chef":
            next(from);
            break;
          case "hr":
            next(from);
            break;
          default:
            next();
            break;
        }
        break;
      case "HrHomepage":
        switch (store.profile.role) {
          case "eating_customer":
            next(from);
            break;
          case "main_chef":
            next(from);
            break;
          case "hr":
            next();
            break;
          default:
            next();
            break;
        }
        break;
      case "Hr Profile Page":
        switch (store.profile.role) {
          case "eating_customer":
            next(from);
            break;
          case "main_chef":
            next(from);
            break;
          case "hr":
            next();
            break;
          default:
            next();
            break;
        }
        break;
      case "Hr Canteen Profile Page":
        switch (store.profile.role) {
          case "eating_customer":
            next(from);
            break;
          case "main_chef":
            next(from);
            break;
          case "hr":
            next();
            break;
          default:
            next();
            break;
        }
        break;
      case "MainChefHomepage":
        switch (store.profile.role) {
          case "eating_customer":
            next(from);
            break;
          case "main_chef":
            next();
            break;
          case "hr":
            next(from);
            break;
          default:
            next();
            break;
        }
        break;
      case "Chef Profile Page":
        switch (store.profile.role) {
          case "eating_customer":
            next(from);
            break;
          case "main_chef":
            next();
            break;
          case "hr":
            next(from);
            break;
          default:
            next();
            break;
        }
        break;

      case "McPortionsPage":
        switch (store.profile.role) {
          case "eating_customer":
            next(from);
            break;
          case "main_chef":
            next();
            break;
          case "hr":
            next(from);
            break;
          default:
            next();
            break;
        }
        break;
      case "McLiveViewPage":
        switch (store.profile.role) {
          case "eating_customer":
            next(from);
            break;
          case "main_chef":
            next();
            break;
          case "hr":
            next(from);
            break;
          default:
            next();
            break;
        }
        break;
      case "Canteen Profile Page":
        switch (store.profile.role) {
          case "eating_customer":
            next(from);
            break;
          case "main_chef":
            next();
            break;
          case "hr":
            next(from);
            break;
          default:
            next();
            break;
        }
        break;
      default:
        next();
        break;
    }
  } else {
    next({ name: "login" });
  }
};

const logoutConfirmPassReset = async (to: any, from: any, next: any) => {
  const store = useAuthStore();
  if (store.isLoggedIn) {
    const { logoutUser } = store;
    await logoutUser();
    next();
  } else {
    next();
  }
};

const nativeCamCheck = () => {
  return nativeCamCheckString;
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:qrcodestring?",
    redirect: (to) => {
      if (to.params.qrcodestring === nativeCamCheck()) {
        return "login/" + to.params.qrcodestring;
      } else {
        return "login";
      }
    },
  },
  {
    path: "/signup/:invemail?/:role?",
    name: "Register",
    component: () => import("../views/SignupPage.vue"),
    beforeEnter: logoutConfirmPassReset,
  },
  {
    path: "/login/:fromsignup?",
    name: "login",
    component: () => import("../views/LoginPage.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/passreset",
    name: "Forgotten Password",
    component: () => import("../views/ForgotPasswordPage.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/confirmpassreset",
    name: "Confirm Password Reset",
    component: () => import("../views/ConfirmResetpassPage.vue"),
    beforeEnter: logoutConfirmPassReset,
  },
  {
    path: "/ecindex/:nccheck?",
    name: "EatingCustomerHomepage",
    component: () => import("../views/EcIndexPage.vue"),
    beforeEnter: authBookCheck,
  },
  {
    path: "/mcindex",
    name: "MainChefHomepage",
    component: () => import("../views/McIndexPage.vue"),
    beforeEnter: authBookCheck,
  },
  {
    path: "/checkin",
    name: "QR Code CheckIn",
    component: () => import("../views/CheckinPage.vue"),
    beforeEnter: authBookCheck,
  },
  {
    path: "/book",
    name: "Booking Page",
    component: () => import("../views/BookingPage.vue"),
    beforeEnter: authBookCheck,
  },
  /*{
    path: "/flexibook",
    name: "Flexi Booking Page",
    component: () => import("../views/FlexiBookingPage.vue"),
    beforeEnter: authBookCheck,
  },*/
  {
    path: "/ecprofile",
    name: "Profile Page",
    component: () => import("../views/UserProfilePage.vue"),
    beforeEnter: authBookCheck,
  },
  {
    path: "/mcprofile",
    name: "Chef Profile Page",
    component: () => import("../views/ChefProfilePage.vue"),
    beforeEnter: authBookCheck,
  },
  {
    path: "/mcliveview",
    name: "McLiveViewPage",
    component: () => import("../views/McLiveViewPage.vue"),
    beforeEnter: authBookCheck,
  },
  {
    path: "/mcportions",
    name: "McPortionsPage",
    component: () => import("../views/McPortionsPage.vue"),
    beforeEnter: authBookCheck,
  },
  {
    path: "/canteenprofile",
    name: "Canteen Profile Page",
    component: () => import("../views/CanteenProfilePage.vue"),
    beforeEnter: authBookCheck,
  },
  {
    path: "/hrindex",
    name: "HrHomepage",
    component: () => import("../views/HrIndexPage.vue"),
    beforeEnter: authBookCheck,
  },
  {
    path: "/hrprofile",
    name: "Hr Profile Page",
    component: () => import("../views/HrProfilePage.vue"),
    beforeEnter: authBookCheck,
  },
  {
    path: "/hrcanteen",
    name: "Hr Canteen Profile Page",
    component: () => import("../views/HrCanteenProfilePage.vue"),
    beforeEnter: authBookCheck,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import axios from "axios";
import { db_usrprofile, appwriteEndpoint, appwriteProjectId, appwriteResetPassConfPage } from "@/config_vars";
import { Account, Client, ID } from 'appwrite';

export const appWriteCreateAccount = async (email: string, password: string, name: string) =>{
  const appWriteClient = new Client()
    .setEndpoint(appwriteEndpoint) // Your API Endpoint
    .setProject(appwriteProjectId);               // Your project ID
  const account = new Account(appWriteClient);

  account.create(ID.unique(), email, password, name);

};

export const appWriteLogin = async (email: string, password: string) => {
  const appWriteClient = new Client()
    .setEndpoint(appwriteEndpoint) // Your API Endpoint
    .setProject(appwriteProjectId);               // Your project ID
  const account = new Account(appWriteClient);


  const promise = account.createEmailSession(email, password);
  
  return promise;
};

export const appWriteGetUser = async () => {
  const appWriteClient = new Client()
    .setEndpoint(appwriteEndpoint) // Your API Endpoint
    .setProject(appwriteProjectId);               // Your project ID
  const account = new Account(appWriteClient);

  const promise = account.get();

  return promise;
};

export const appGetUserCurrentSession = async () => {
  const appWriteClient = new Client()
    .setEndpoint(appwriteEndpoint) // Your API Endpoint
    .setProject(appwriteProjectId);               // Your project ID
  const account = new Account(appWriteClient);

  const promise = await account.getSession('current');

  return promise;
};

export const fbCreateAccount = async (email: string, password: string, name: string) => {
  const appWriteClient = new Client()
    .setEndpoint(appwriteEndpoint) // Your API Endpoint
    .setProject(appwriteProjectId);               // Your project ID

  const account = new Account(appWriteClient);

  const response = await account.create(ID.unique(), email, password, name);

  //appWriteLogin(email, password);

  if(response){
    return{
      user: response,
    }
  } else {
    return{
      user: null,
    }
  }
};

export const fbSignIn = async (email: string, password: string) => {

  const logus = await appWriteLogin(email, password);

  return logus;
};

export const fbSignOut = async () => {
  const appWriteClient = new Client()
    .setEndpoint(appwriteEndpoint) // Your API Endpoint
    .setProject(appwriteProjectId);               // Your project ID

  const account = new Account(appWriteClient);
  account.deleteSession( 'current' );

  return true;
};

export const fbResetPass = async (email: string) => {
  const appWriteClient = new Client()
    .setEndpoint(appwriteEndpoint) // Your API Endpoint
    .setProject(appwriteProjectId);               // Your project ID
  const account = new Account(appWriteClient);
  const promise = await account.createRecovery(email, appwriteResetPassConfPage);

  return promise;
};

export const fbResetPassConfirm = async (userId: any, secret: any, password: any) => {
  const appWriteClient = new Client()
    .setEndpoint(appwriteEndpoint) // Your API Endpoint
    .setProject(appwriteProjectId);               // Your project ID
  const account = new Account(appWriteClient);
  const promise = account.updateRecovery(userId, secret, password, password);

  return promise;
};

export const fbAuthStateListener = async (callback: any) => {
  const appWriteClient = new Client()
    .setEndpoint(appwriteEndpoint) // Your API Endpoint
    .setProject(appwriteProjectId);               // Your project ID

  const account = new Account(appWriteClient);

  const promiseGetSession = account.getSession('current');

  promiseGetSession.then(function (response) {
    callback(response);
  }, function () {
    callback(null);
  });

};

export const getUserProfile = async () => {
  let uProf = null;

  await appWriteGetUser().then(async function (response) {
    await axios
    .get(db_usrprofile, {
      params: {
        uid: response.$id,
        getUserRoleProfile: true,
        usess: await appGetUserCurrentSession()
      },
    })
    .then((response) => {
      uProf = response.data;
    })
    .catch(function () {
      uProf = null;
    });
  }, function () {
    uProf = null;
  });

  return uProf;

};

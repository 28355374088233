
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonButtons,
  IonButton,
  useIonRouter,
  menuController,
  IonAlert
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
} from "ionicons/icons";
import { useAuthStore } from "./store";
import update from './mixins/update';
import { appWriteGetUser, appGetUserCurrentSession } from "./store/firebase";
import axios from "axios";
import { ec_acti_log } from "@/config_vars";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonButtons,
    IonButton,
    IonAlert
  },
  methods: {
    handleVisibility() {
      if (document.visibilityState !== "hidden") {
        location.reload();
      }
    },
  },
  ionViewDidEnter() {
    menuController.enable(false, "ecMenu");
    menuController.enable(false, "mcMenu");
    menuController.enable(false, "hrMenu");
  },
  mixins: [update],
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: "Hjem",
        url: "/ecindex",
      },
      {
        title: "Check-in",
        url: "/Checkin",
      },
      {
        title: "Book frokost",
        url: "/book",
      },
      /*{
        title: "Flexi frokost",
        url: "/flexibook",
      },*/
      {
        title: "Min profil",
        url: "/ecprofile",
      },
    ];

    const chefAppPages = [
      {
        title: "Hjem",
        url: "/mcindex",
      },
      {
        title: "Min Profil",
        url: "/mcprofile",
      },
      {
        title: "Kantine",
        url: "/canteenprofile",
      },
      {
        title: "Live View",
        url: "/mcliveview",
      },
      {
        title: "Portions",
        url: "/mcportions",
      },
    ];

    const hrAppPages = [
      {
        title: "Hjem",
        url: "/hrindex",
      },
      {
        title: "Min Profil",
        url: "/hrprofile",
      },
      {
        title: "Kantine",
        url: "/hrcanteen",
      },
    ];

    const route = useRoute();
    const ionRouter = useIonRouter();
    const store = useAuthStore();
    const { logoutUser, user } = store;
    const doLogout = async () => {

      const user = await appWriteGetUser();
      if (user) {
        const formData = new FormData();
        formData.append("uid", user.$id);
        formData.append("ecEmail", user.email as string);
        formData.append("logoutLog", "true");
        const usess = await appGetUserCurrentSession();
        formData.append("usess", JSON.stringify(usess));

        await axios({
          method: "post",
          url: ec_acti_log,
          data: formData,
        });
      }

      await logoutUser();

      if (await menuController.isEnabled("ecMenu")) {
        menuController.close("ecMenu");
      }

      if (await menuController.isEnabled("mcMenu")) {
        menuController.close("mcMenu");
      }

      if (await menuController.isEnabled("hrMenu")) {
        menuController.close("hrMenu");
      }

      ionRouter.push("/login");
    };

    const navMenu = (index: number, url: string) => {
      selectedIndex.value = index;
      ionRouter.navigate(url, "forward", "replace");
    };

    return {
      selectedIndex,
      appPages,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      store,
      user,
      chefAppPages,
      hrAppPages,
      navMenu,
      doLogout,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
    };
  },
});
